import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { Translator, Translate } from "react-auto-translate";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  URL_ACTIVE_PAGE_V2,
  URL_API_ACTIVITY_DETAIL,
  URL_API_ACTIVITY_RELATE,
  URL_VIEW_POSTWEB,
} from "../../utils/constants";
import { getDataByID, getTakenData } from "../../utils/service";
import BannerCommon from "../../components/BannerCommon";
import Breadcrumb from "../../components/BreadcrumbCommon";
import SliderCommon from "../../components/SliderCommon";

import "./../../assets/styles/activity/activity.scss";

const ActivityPageDetail = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const [dataDetail, setDataDetail] = useState({});
  const [dataRelated, setDataRelated] = useState([]);
  const [imageBanner, setImageBanner] = useState("");

  useEffect(() => {
    if (id) {
      getTakenData(URL_API_ACTIVITY_DETAIL(id))
        .then((res) => {
          setDataDetail(res?.data);
          setImageBanner(res?.data?.image);
        })
        .catch((error) => error);
      getTakenData(URL_API_ACTIVITY_RELATE(id))
        .then((res) => setDataRelated(res?.data))
        .catch((error) => error);
      getDataByID(URL_VIEW_POSTWEB, id);
    }
  }, [id]);

  return (
    <div className="wrapActivity">
      <div className="wrapActivity__banner">
        <BannerCommon text1={dataDetail?.title} img={imageBanner} />
      </div>

      <div className="container">
        <Breadcrumb
          dataBreadcrumb={[
            { title: t("activity.home"), url: "/" },
            { title: t("activity.title-detail"), url: "/activity-new" },
            { title: dataDetail?.title },
          ]}
        />
        <div className="content-category-document">
          <p
            className="ck-content"
            dangerouslySetInnerHTML={{ __html: dataDetail?.content }}
          ></p>
        </div>

        <div className="container__carouselActivity">
          <SliderCommon
            dataRelated={dataRelated}
            path={URL_ACTIVE_PAGE_V2}
            titleHeader={t("activity.relate")}
          />
        </div>
      </div>
    </div>
    // </Translator>
  );
};

export default ActivityPageDetail;
