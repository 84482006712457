import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { Translate } from "react-auto-translate";
import { Col, Row } from "antd";

import { URL_API_NEWS } from "../../utils/constants";
import { postData } from "../../utils/service";

import defaultBanner from "./../../assets/images/default_image.svg";

const SectionNew = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [dataBlockNews, setDataBlockNews] = useState([]);

  const getDataNews = () => {
    try {
      postData(URL_API_NEWS, { is_show_new: true }).then((res) => {
        const dataNew = res?.data?.data?.sort((a, b) => {
          return new Date(b?.created_at) - new Date(a?.created_at);
        });

        setDataBlockNews(dataNew);
      });
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    getDataNews();
  }, []);

  return (
    <section className="section-activity-home" id="section-activity">
      <div className="block-title-main__title">
        <p className="block-title-main__title-main">
          <Translate>{t("home-page.title-new")}</Translate>
        </p>
      </div>
      <div className="block-item-activity">
        <Row gutter={[{ xs: 8, lg: 16, sm: 16, xl: 24, md: 16, xxl: 24 }, 24]}>
          {dataBlockNews?.slice(0, 8)?.map((item) => {
            return (
              <Col xs={24} sm={12} md={12} lg={8} xl={6} key={item?.id}>
                <div className="block-item-activity__content">
                  <div
                    className="block-item-activity__content--image"
                    onClick={() => history.push(`/web-news/${item?.id}`)}
                  >
                    <img
                      src={
                        item?.thumbnail || item?.image
                          ? process?.env?.REACT_APP_ENDPOINT +
                              item?.thumbnail || item?.image
                          : defaultBanner
                      }
                      alt={item?.title || ""}
                    />
                  </div>

                  <Link to={`/web-news/${item?.id}`}>
                    <Translate>{item?.title}</Translate>
                  </Link>
                  <div className="block-item-activity__content--date">
                    <hr />
                    <p>{item?.created_at}</p>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
    </section>
  );
};

export default SectionNew;
