import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";

import {
  URL_API_NEWS_DETAIL,
  URL_API_NEWS_RELATE,
  URL_API_SERI_ID,
  URL_API_SERI_INFOMATION,
  URL_VIEW_INFORMATION,
  URL_WEB_NEWS,
} from "../../utils/constants";
import { getDataByID, getTakenData, postData } from "../../utils/service";

import BannerCommon from "../../components/BannerCommon";
import Breadcrumb from "../../components/BreadcrumbCommon";
import SliderCommon from "../../components/SliderCommon";
import CardAuthor from "../../components/CardAuthor";
import HashtagCommon from "../../components/Hashtag";
import CardInformationCommon from "../../components/CardInformationCommon";

import right from "../../assets/images/icons/right.svg";

import "../../assets/styles/category-document/category-document.scss";
import "./slider.scss";

const InformationDetail = () => {
  const { t } = useTranslation("translation");
  const { id } = useParams();

  const [dataDetail, setDataDetail] = useState({});
  const [dataRelated, setDataRelated] = useState([]);
  const [imageBanner, setImageBanner] = useState("");
  const [dataSeri, setDataSeri] = useState([]);
  const [dataSeriInfomation, setDataSeriInfomation] = useState([]);
  const [isSeri, setIsSeri] = useState(false);

  const numberdataSlider = dataSeriInfomation.length;
  const settings = {
    dots: false,
    swipeToSlide: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    infinite: numberdataSlider <= 2 ? false : true,
    autoplay: false,
    prevArrow:
      numberdataSlider <= 2 ? null : (
        <div>
          <img src={right} alt="" style={{ transform: "rotate(180deg)" }} />
        </div>
      ),
    nextArrow: numberdataSlider <= 2 ? null : <img src={right} alt="" />,
    vertical: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          infinite: numberdataSlider <= 1 ? false : true,
          prevArrow:
            numberdataSlider <= 1 ? null : (
              <div>
                <img
                  src={right}
                  alt=""
                  style={{ transform: "rotate(180deg)" }}
                />
              </div>
            ),
          nextArrow: numberdataSlider <= 1 ? null : <img src={right} alt="" />,
        },
      },
      {
        breakpoint: 767.99,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: false,
          infinite: numberdataSlider <= 2 ? false : true,
          prevArrow:
            numberdataSlider <= 2 ? null : (
              <div>
                <img
                  src={right}
                  alt=""
                  style={{ transform: "rotate(180deg)" }}
                />
              </div>
            ),
          nextArrow: numberdataSlider <= 2 ? null : <img src={right} alt="" />,
        },
      },
      {
        breakpoint: 575.99,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: false,
          infinite: numberdataSlider <= 1 ? false : true,
          prevArrow:
            numberdataSlider <= 1 ? null : (
              <div>
                <img
                  src={right}
                  alt=""
                  style={{ transform: "rotate(180deg)" }}
                />
              </div>
            ),
          nextArrow: numberdataSlider <= 1 ? null : <img src={right} alt="" />,
        },
      },
      {
        breakpoint: 374.99,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: false,
          infinite: numberdataSlider <= 1 ? false : true,
          prevArrow:
            numberdataSlider <= 1 ? null : (
              <div>
                <img
                  src={right}
                  alt=""
                  style={{ transform: "rotate(180deg)" }}
                />
              </div>
            ),
          nextArrow: numberdataSlider <= 1 ? null : <img src={right} alt="" />,
        },
      },
    ],
  };
  useEffect(() => {
    if (id) {
      getTakenData(URL_API_NEWS_RELATE(id))
        .then((res) => {
          setDataRelated(res?.data);
        })
        .catch((error) => error);

      getTakenData(URL_API_NEWS_DETAIL(id))
        .then((res) => {
          setDataDetail(res?.data);
          setImageBanner(res?.data?.thumbnail);
          return res?.data;
        })
        .then((res) => {
          if (res?.seri_id) {
            const seri_id = res?.seri_id;
            const id = res?.id;
            setIsSeri(true);
            getTakenData(URL_API_SERI_ID(seri_id))
              .then((res) => {
                setDataSeri(res?.data);
              })
              .catch((err) => {
                return err;
              });

            postData(URL_API_SERI_INFOMATION, {
              keyword: "",
              page: 1,
              per_page: 10,
              seri_id: seri_id,
              information_id: id,
            })
              .then((res) => {
                setDataSeriInfomation(res?.data?.data);
              })
              .catch((err) => {
                setIsSeri(false);
                return err;
              });
          }
        })
        .catch((error) => error);

      getDataByID(URL_VIEW_INFORMATION, id);
    }
  }, [id]);

  const sharedBy = [
    { id: "3", value: "teacher" },
    { id: "4", value: "newBlackBelt" },
    { id: "5", value: "student" },
    { id: "1", value: "orther" },
  ];

  const renderShareBy = (id) => {
    return sharedBy.find((item) => Number(item.id) === id)?.value;
  };

  const dataHashtag = dataDetail?.hashtag;

  return (
    <div className="category-document">
      <div className="category-document__banner">
        <BannerCommon text1={dataDetail.title} img={imageBanner} />
      </div>

      <div className="container">
        <Breadcrumb
          dataBreadcrumb={[
            { title: t("information.home"), url: "/" },
            {
              title: t(
                `information.${renderShareBy(dataDetail?.info_catalog_id)}`
              ),
              url: `/web-news-by?${dataDetail?.info_catalog_id}`,
            },
            { title: dataDetail.title },
          ]}
        />
        <CardAuthor
          dataAuthor={{
            image: dataDetail?.user?.avatar,
            name: dataDetail?.user?.name,
            position: dataDetail?.infoBranch,
            date: dataDetail?.updated_at,
          }}
        />
        <div className="content-category-document">
          <p
            className="ck-content"
            dangerouslySetInnerHTML={{ __html: dataDetail?.content }}
          ></p>
        </div>
        <div className="container__hashtag">
          <HashtagCommon dataHashtag={dataHashtag} />
        </div>
        {isSeri && (
          <div className="containerSeri">
            <div className="containerSeri__contentSeri">
              <div className="containerSeri__contentSeri-name">
                {dataSeri?.name}
              </div>
              <div className="containerSeri__contentSeri-content">
                {dataSeri?.description}
              </div>
            </div>
            {dataSeriInfomation && (
              <div className="containerSeri__caroselSeri">
                <>
                  {dataSeriInfomation?.length !== 0 ? (
                    <div className="wrapSliderSeri">
                      {dataSeriInfomation?.length > 1 ? (
                        <div className="wrapSliderSeri__container">
                          <Slider {...settings}>
                            {dataSeriInfomation?.map((item) => (
                              <div
                                key={item?.id}
                                className="wrapSliderSeri__card"
                              >
                                <CardInformationCommon
                                  data={{
                                    header: item?.menu_name,
                                    image:
                                      item?.thumbnail ||
                                      item?.image ||
                                      item?.attach_files?.[0],
                                    title: item?.title,
                                    description: item?.description,
                                    id: item?.id,
                                    date: item?.created_at,
                                    path: URL_WEB_NEWS,
                                  }}
                                />
                              </div>
                            ))}
                          </Slider>
                        </div>
                      ) : (
                        <div className="wrapSliderSeri__small">
                          <CardInformationCommon
                            data={{
                              header: dataSeriInfomation?.[0].menu_name,
                              image:
                                dataSeriInfomation?.[0].thumbnail ||
                                dataSeriInfomation?.[0].image ||
                                dataSeriInfomation?.[0].attach_files?.[0],
                              title: dataSeriInfomation?.[0].title,
                              description: dataSeriInfomation?.[0].description,
                              id: dataSeriInfomation?.[0].id,
                              date: dataSeriInfomation?.[0].created_at,
                              path: URL_WEB_NEWS,
                            }}
                          />
                        </div>
                      )}
                    </div>
                  ) : null}
                </>
              </div>
            )}
          </div>
        )}

        <div className="container__carouselInformation">
          <SliderCommon
            dataRelated={dataRelated}
            path={URL_WEB_NEWS}
            titleHeader={t("information.relate")}
          />
        </div>
      </div>
    </div>
  );
};

export default InformationDetail;
