import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getTakenData, postData } from "../utils/service";
import { URL_API_TECHNICAL, URL_GROUP_TECHNIQUES } from "../utils/constants";
import { useHistory } from "react-router-dom";
import { Col, Row } from "antd";
import { ReactSVG } from "react-svg";

import arrow_right from "./../assets/images/icons/arrow_right.svg";
import defaultImage from "./../assets/images/default_image.svg";

import "../assets/styles/block-technical/block-technical.scss";

const BlockTechnical = () => {
  const [dataBlockTechnical, setDataBlockTechnical] = useState([]);
  const history = useHistory();
  const { t } = useTranslation("translation");
  useEffect(() => {
    getTakenData(URL_GROUP_TECHNIQUES)
      .then((res) => {
        setDataBlockTechnical(res?.data?.[0]?.submenus);
      })
      .catch((err) => {
        return err;
      });
  }, []);

  const handleInformationDetails = (id) => {
    history.push(`/group-techniques/${id}`);
  };
  return (
    <div className="blockTechnical">
      <div className="blockTechnical_header">
        <p className="blockTechnical_header_text">
          {t("block-technical.header")}{" "}
        </p>
      </div>
      <div className="blockTechnical_wrapper">
        <Row gutter={[{ xs: 8, lg: 16, sm: 16, xl: 24, md: 16, xxl: 24 }, 24]}>
          {dataBlockTechnical?.map((item) => (
            <Col xs={24} sm={12} lg={8} xl={6} key={item?.id}>
              <div className="blockTechnical_wrapper_container">
                <div
                  className="blockTechnical_wrapper_container_imageWrapper"
                  onClick={() => handleInformationDetails(item?.id)}
                >
                  <img
                    className="blockTechnical_wrapper_container_image"
                    src={
                      process.env.REACT_APP_ENDPOINT + item?.image ||
                      defaultImage
                    }
                    alt={item?.title || ""}
                  />
                </div>

                <p
                  className="blockTechnical_wrapper_container_title"
                  onClick={() => handleInformationDetails(item?.id)}
                >
                  {item?.name}
                </p>
                <div
                  className="blockTechnical_wrapper_container_button"
                  onClick={() => handleInformationDetails(item?.id)}
                >
                  <p className="blockTechnical_wrapper_container_button_text">
                    {t("block-technical.button")}
                  </p>
                  <ReactSVG
                    src={arrow_right}
                    className="block_container_button_arrow"
                  />
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};
export default BlockTechnical;
