import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { message, Skeleton } from "antd";

import { getTakenData, postData } from "../../utils/service";
import { URL_API_BANNERS, URL_GROUP_TECHNIQUES } from "../../utils/constants";

import CardClub from "../../components/CardClub";
import Pagination from "./../../components/pagination";
import BreadcrumbComponent from "../../components/BreadcrumbCommon";
import BannerCommon from "../../components/BannerCommon";

import { useTranslation } from "react-i18next";

const GroupTechniques = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [techniquesList, setTechniquesList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [imageBanner, setImageBanner] = useState("");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 576);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const skeletonsDefault = Array.from({ length: 6 });

  const dataBreadcrumbs = [
    { title: t("club-list.home"), url: "/" },
    {
      title: t("groupTechniques.techniques"),
    },
  ];

  useEffect(() => {
    getTakenData(URL_GROUP_TECHNIQUES)
      .then((res) => {
        setTechniquesList(res?.data?.[0]?.submenus);
      })
      .catch((error) =>
        message.error(error || t("groupTechniques.techniquesFailed"))
      )
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    postData(URL_API_BANNERS, { is_show: true })
      .then((res) => {
        const imageActivity = res?.data?.data?.find(
          (item) => item.type === "TECHNIQUE"
        );
        setImageBanner(
          isMobile ? imageActivity?.image_mobile : imageActivity?.image
        );
      })
      .catch((err) => {
        return err;
      });
  }, []);

  const handleGroupTechniques = (id) => {
    setLoading(true);
    history.push(`group-techniques/${id}`);
  };

  return (
    <>
      <BannerCommon img={imageBanner} text1={t("groupTechniques.techniques")} />
      <div className="container ">
        <BreadcrumbComponent dataBreadcrumb={dataBreadcrumbs} />
        <div className="content row">
          {loading
            ? skeletonsDefault?.map((_, index) => (
                <Skeleton
                  key={index}
                  active
                  className="col-12 col-sm-6 col-lg-4 col-xl-3"
                />
              ))
            : techniquesList?.length > 0 &&
              techniquesList?.map((techniques) => (
                <div
                  key={techniques?.id}
                  id={techniques?.id}
                  className="col-12 col-sm-6 col-lg-4 col-xl-3 content__card"
                >
                  <CardClub
                    data={techniques}
                    handleClick={handleGroupTechniques}
                  />
                </div>
              ))}
        </div>
      </div>
    </>
  );
};

export default GroupTechniques;
