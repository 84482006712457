import { ReactSVG } from "react-svg";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  URL_API_DETAIL_DOCUMENT,
  URL_VIEW_LIBRARY,
} from "../../utils/constants";
import { getDataByID, getTakenData } from "../../utils/service";

import BannerCommon from "../../components/BannerCommon";
import BreadcrumbComponent from "../../components/BreadcrumbCommon";

import downloadIcon from "./../../assets/images/icons/download-icon.svg";
import docxIcon from "./../../assets/images/icons/docx-icon.svg";

import "../../assets/styles/category-document/category-document.scss";

const CategoryDocumentDetail = () => {
  const { id, idCategory } = useParams();
  const [dataDetailDocument, setDataDetailDocument] = useState({});
  const { t } = useTranslation();
  useEffect(() => {
    getDataDetailDocument();
    getDataByID(URL_VIEW_LIBRARY, id);
  }, [id]);

  const getDataDetailDocument = () => {
    try {
      if (id) {
        getTakenData(URL_API_DETAIL_DOCUMENT(id)).then((res) => {
          setDataDetailDocument(res?.data);
        });
      }
    } catch (error) {
      setDataDetailDocument({});
    }
  };
  const breadcrumb = [
    {
      title: t("library.home"),
      url: "/",
    },
    {
      title: t("library.library"),
      url: "/document",
    },
    {
      title: dataDetailDocument?.title,
    },
  ];
  return (
    <div className="wrapDocumentDetail">
      <div className="wrapDocumentDetail__banner">
        <BannerCommon text1={dataDetailDocument?.title} />
      </div>
      <div className="category-document container">
        <div className="banner-category-document">
          <BreadcrumbComponent dataBreadcrumb={breadcrumb} />
          <p className="title-category-document">{dataDetailDocument?.title}</p>
        </div>
        <div className="content-category-document">
          <div
            dangerouslySetInnerHTML={{ __html: dataDetailDocument?.content }}
            className="content-main ck-content"
          ></div>
        </div>

        {dataDetailDocument?.attach_files?.length > 0 && (
          <div className="dowload-wrap">
            {dataDetailDocument?.attach_files?.map((item) => (
              <div className="dowload-wrap_card" key={item?.id}>
                <p className="dowload-wrap_card__title">
                  <ReactSVG src={docxIcon} width={24} height={24} />
                  {dataDetailDocument?.title}
                </p>
                <div className="dowload-wrap_card__button">
                  <button
                    onClick={() => {
                      window.location.href = `${process.env.REACT_APP_ENDPOINT_API}download?file=${dataDetailDocument?.attach_files?.[0]}&name=${dataDetailDocument?.title}`;
                    }}
                  >
                    <ReactSVG src={downloadIcon} />
                    <span>{t("groupTechniques.download")}</span>
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default CategoryDocumentDetail;
