import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Menu, message, Skeleton } from "antd";

import { useTranslation } from "react-i18next";

import { getDataByID, getTakenData } from "../../../utils/service";
import {
  URL_API_ACTIVITY_RELATE,
  URL_API_DETAIL_TECHNICAL,
  URL_GROUP_TECHNIQUES,
  URL_TECH,
  URL_TECH_DETAIL,
  URL_VIEW_POSTWEB,
} from "../../../utils/constants";

import BreadcrumbComponent from "../../../components/BreadcrumbCommon";
import BannerCommon from "../../../components/BannerCommon";
import SliderCommon from "../../../components/SliderCommon";

import downloadIcon from "../../../assets/images/icons/download-icon.svg";
import docxIcon from "../../../assets/images/icons/docx-icon.svg";

import "../../../assets/styles/techniques/techniques.scss";

const TechniquesDetails = () => {
  const { t } = useTranslation();
  const { idTechniques, id } = useParams();

  const [infomationTechniques, setInfomationTechniques] = useState({});
  const [techniquesDetails, setTechniquesDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [nameGroupTechiques, setNameGroupTechiques] = useState([]);
  const [imageBanner, setImageBanner] = useState("");
  const [dataRelated, setDataRelated] = useState([]);

  useEffect(() => {
    getTakenData(URL_GROUP_TECHNIQUES)
      .then((res) => {
        setNameGroupTechiques(res?.data?.[0].submenus);
        const convertGroupTechiques = res?.data?.[0]?.submenus?.filter(
          (item) => item?.id == idTechniques
        );

        setInfomationTechniques(convertGroupTechiques?.[0]);
      })
      .catch((error) =>
        message.error(error || t("groupTechniques.techniquesFailed"))
      )
      .finally(() => setLoading(false));
  }, [idTechniques, t]);

  useEffect(() => {
    if (id) {
      getDataByID(URL_API_DETAIL_TECHNICAL, id)
        .then((res) => {
          setTechniquesDetails(res?.data);
          setImageBanner(res?.data.image);
        })
        .catch((error) =>
          message.error(error || t("groupTechniques.techniquesFailed"))
        )
        .finally(() => setLoading(false));

      getTakenData(URL_API_ACTIVITY_RELATE(id))
        .then((res) => setDataRelated(res?.data))
        .catch((error) => error);
    }
  }, [id, t]);

  useEffect(() => {
    getDataByID(URL_VIEW_POSTWEB, id);
  }, [id]);

  const PATH = `${URL_TECH}${idTechniques}${URL_TECH_DETAIL}`;

  const menu = (
    <Menu>
      {nameGroupTechiques?.map((data) => {
        return (
          <Menu.Item key={data?.id}>
            <Link to={`/group-techniques/${data?.id}`}>{data?.name}</Link>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const dataBreadcrumbs = [
    { title: t("club-list.home"), url: "/" },
    {
      title: t("groupTechniques.techniques"),
      url: "/group-techniques",
      menu: menu,
    },
    {
      title: !infomationTechniques?.name
        ? `${t("groupTechniques.techniques")}...`
        : `${infomationTechniques?.name}`,
      url: `/group-techniques/${infomationTechniques?.id}`,
    },
    {
      title: !techniquesDetails?.title
        ? `${t("groupTechniques.techniques")}...`
        : `${techniquesDetails?.title}`,
    },
  ];

  return (
    <>
      <BannerCommon
        img={imageBanner}
        text1={
          !techniquesDetails?.title
            ? `${t("groupTechniques.techniques")}...`
            : `${techniquesDetails?.title}`
        }
      />

      <div className="container techniques-details-wrap">
        <BreadcrumbComponent dataBreadcrumb={dataBreadcrumbs} />

        {loading ? (
          <Skeleton active />
        ) : (
          <>
            <div className="content-category-document">
              <p
                dangerouslySetInnerHTML={{ __html: techniquesDetails?.content }}
                className="content-main"
              />
            </div>
            {techniquesDetails?.attach_files?.length > 0 && (
              <div className="dowload-wrap">
                {techniquesDetails?.attach_files?.map((item) => (
                  <div className="dowload-wrap_card" key={item?.id}>
                    <p className="dowload-wrap_card__title">
                      <ReactSVG src={docxIcon} width={24} height={24} />
                      {techniquesDetails?.title}
                    </p>
                    <div className="dowload-wrap_card__button">
                      <button
                        onClick={() => {
                          window.open(
                            `${process.env.REACT_APP_ENDPOINT_API}download?file=${techniquesDetails?.attach_files?.[0]}&name=${techniquesDetails?.title}`
                          );
                        }}
                      >
                        <ReactSVG src={downloadIcon} />
                        <span>{t("groupTechniques.download")}</span>
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </>
        )}
        <div className="container__carouselActivity">
          <SliderCommon
            dataRelated={dataRelated}
            path={PATH}
            titleHeader={t("groupTechniques.relate")}
          />
        </div>
      </div>
    </>
  );
};

export default TechniquesDetails;
