import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Form, Row, message } from "antd";

import { postData } from "../../utils/service";
import { CONTACT_INFO, URL_API_BANNERS } from "../../utils/constants";

import InputCommon from "../../components/forms/Input";
import BreadcrumbComponent from "../../components/BreadcrumbCommon";
import TextAreaCommon from "../../components/forms/TextArea";
import BannerCommon from "../../components/BannerCommon";
import MapContact from "../../components/MapContact";

import iconPhone from "./../../assets/images/icons/icon-phone.svg";
import iconFax from "./../../assets/images/icons/icon-fax.svg";
import iconEmail from "./../../assets/images/icons/icon-email.svg";

import "../../assets/styles/contact/contact.scss";

const ContactV2 = () => {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [imageBanner, setImageBanner] = useState("");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 576);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const dataBreadcrumbs = [
    { title: t("contact.home"), url: "/" },
    {
      title: t("contact.contact"),
    },
  ];

  useEffect(() => {
    postData(URL_API_BANNERS, { is_show: true })
      .then((res) => {
        const imageActivity = res?.data?.data?.find(
          (item) => item.type === "CONTACT"
        );

        setImageBanner(
          isMobile ? imageActivity?.image_mobile : imageActivity?.image
        );
      })
      .catch((err) => {
        return err;
      });
  }, []);

  const onFinish = async (values) => {
    setIsLoading(true);
    try {
      await postData(CONTACT_INFO, values);
      message.success({
        content: "Gửi thành công",
        style: {
          marginTop: "15vh",
        },
      });
      form.resetFields();
      setIsLoading(false);
    } catch (error) {
      message.info("Gửi thông tin lỗi");
    }
  };
  console.log("imageBanner", imageBanner);

  return (
    <section className="wrap">
      <BannerCommon img={imageBanner} text1={t("contact.contact")} />
      <section className="container">
        <BreadcrumbComponent dataBreadcrumb={dataBreadcrumbs} />
        <div className="main">
          <Row gutter={[24]}>
            <Col lg={12} md={24}>
              <Form form={form} onFinish={onFinish}>
                <p className="title-form">{t("contact.contact")}</p>
                <p className="description-form">{t("contact.description")}</p>
                <Row gutter={[16, 24]}>
                  <Col span={24}>
                    <Form.Item
                      name="name"
                      rules={[
                        { required: true, message: t("contact.message-name") },
                      ]}
                    >
                      <InputCommon
                        label={t("contact.fullName")}
                        placeholder={t("contact.fullName")}
                        isRequired
                      />
                    </Form.Item>
                  </Col>

                  <Col sm={12} xs={24}>
                    <Form.Item
                      name="phone"
                      rules={[
                        {
                          pattern: /^[0-9]{10}$/,
                          message: t("contact.message-number"),
                        },
                        {
                          required: true,
                          message: t("contact.message1-number"),
                        },
                      ]}
                    >
                      <InputCommon
                        label={t("contact.contactNumber")}
                        placeholder={t("contact.contactNumber")}
                        isRequired
                      />
                    </Form.Item>
                  </Col>
                  <Col sm={12} xs={24}>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          type: "email",
                          message: t("contact.message-email"),
                        },
                        {
                          required: true,
                          message: t("contact.message1-email"),
                        },
                      ]}
                    >
                      <InputCommon
                        label="Email"
                        placeholder="Email"
                        isRequired
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item name="message">
                      <TextAreaCommon
                        label={t("contact.message")}
                        placeholder={t("contact.message")}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <button
                  type="submit"
                  isLoading={isLoading}
                  disabled={isLoading}
                >
                  <span className="content-btn">
                    {t("contact.sendMessage")}
                  </span>
                </button>
              </Form>

              <Row>
                <Col sm={10} xs={24}>
                  <div className="wrap-information">
                    <img src={iconPhone} alt="" />
                    <div className="wrap-info">
                      <div className="title">{t("contact.contactNumber")}</div>
                      <div className="title-info">
                        <a href="tel:+84 91 342 57 49">
                          {t("contact.phoneNumber")}
                        </a>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={10} xs={24}>
                  <div className="wrap-information">
                    <img src={iconEmail} alt="" />
                    <div className="wrap-info">
                      <div className="title">{t("contact.email")}</div>
                      <div className="title-info">
                        <a href="mailto: nghiadungkarate.co@gmail.com">
                          {t("contact.emailDetail")}
                        </a>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={12} md={24} sm={24} xs={24}>
              <MapContact />
            </Col>
          </Row>
        </div>
      </section>
    </section>
  );
};

export default ContactV2;
